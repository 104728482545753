@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Source+Sans+Pro:wght@700&display=swap");

:root {
  --normal-font-family: "Poppins", sans-serif;
  --head-font-family: "Source Sans Pro", sans-serif;
  --background-color: #7f53ac;
  --background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
}
body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  width: 0.75em;
}

body::-webkit-scrollbar-track {
  background-color: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
  background-image: var(--background-image);
  border-radius: 1vw;
}
.flex-box,
.flex-x {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bg-pitch-dark {
  background-color: rgb(24, 24, 24);
}

.flex-x {
  flex-direction: row;
}
.banner {
  width: 100vw;
  padding: 1.5vw;
  background-color: var(--background-color);
  background-image: var(--background-image);
}
.purple-bg {
  background-color: var(--background-color);
  background-image: var(--background-image);
}
i {
  color: rgb(43, 42, 42);
  font-size: 2vw;
  margin: 0.75vw;
  color: transparent;
  background-clip: text;
  background-color: var(--background-color);
  background-image: var(--background-image);
}

.img {
  height: 70vh;
  width: 70vw;
}
h1 {
  font-family: var(--head-font-family);
}
p,
a,
h5,
li {
  font-family: var(--normal-font-family);
}
.btn {
  color: #fff;
  font-family: var(--head-font-family);
}
.skill-svg {
  height: 10vw;
  width: 10vw;
}
#mycard {
  box-shadow: rgba(127, 83, 172, 0.25) 0px 50px 100px -20px,
    rgb(100, 125, 238, 0.5) 0px 30px 60px -30px;
  border: none;
  border-radius: 4vw;
  padding:5vw;
}

.spl-btn {
  color: #fff;
  border: var(--background-color);
  padding: 5vw;
  margin: 0.5vw;
  background-color: var(--background-color);
  background-image: var(--background-image);
}
.spl-text {
  background-image: var(--background-image);
  background-clip: text;
  color: transparent;
  font-size: 5vw;
}
.txt {
  font-size: 4vw;
}
.primary {
  padding: 3vw;
}
.carousel,
.carousel-subs {
  background-color: var(--background-color);
  background-image: var(--background-image);
  color: #fff;
  height: 90vh;
  width: 100vw;
  margin: 0 0 3vw 0;
}

@media (max-width: 550px) {
  i {
    font-size: 6vw;
  }
  .banner {
    margin-bottom: 2.5vw;
  }
  .primary {
    padding: 0 2vw;
    margin: 1.5vw;
  }
  .img {
    width: 60vw;
    height: 50vh;
  }
  .carousel {
    height: 85vh;
  }
}
